.mobile-block {
  display: none;
}
@media (max-width: 1140px) {
  .card-deal .col-heading {
    min-width: 280px;
    -ms-flex-preferred-size: 280px;
    flex-basis: 280px;
  }
  .nav-home-aside {
    width: 200px;
  }
}
@media (max-width: 992px) {
  .table-shopping-cart tr td:first-child {
    width: 250px;
  }
  .table-shopping-cart .itemside .info {
    padding: 0;
  }
  .table-shopping-cart .itemside .aside {
    display: none;
  }
  .card-deal {
    display: block;
  }
  .card-deal .col-heading {
    min-width: 280px;
  }
  .slider-home-banner .carousel-item img {
    max-height: 350px;
  }
}
@media all and (max-width: 768px) {
  .nav-home-aside {
    width: 100%;
    margin-bottom: 20px;
  }
  .nav-home-aside .title-category {
    cursor: pointer;
    padding: 10px 10px;
    display: block;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0px 2px 3px rgba(102, 102, 102, 0.1);
    box-shadow: 0px 2px 3px rgba(102, 102, 102, 0.1);
    border-radius: 5px;
  }
  .nav-home-aside .title-category .icon {
    float: right;
    margin-top: 3px;
  }
  .nav-home-aside .menu-category {
    margin-bottom: 20px;
    display: none;
  }
  .nav-home-aside .menu-category li {
    position: relative;
    display: block;
  }
  .nav-home-aside .menu-category a {
    color: #212529;
  }
  .nav-home-aside .menu-category > li > a {
    display: block;
    padding: 10px 0px;
  }
  .nav-home-aside .menu-category .submenu {
    display: none;
    -webkit-box-shadow: initial;
    box-shadow: initial;
    border: 1px solid #ddd;
    position: static;
    left: 0;
    top: 0;
    width: auto;
    background: #fff;
  }
  .nav-home-aside .menu-category li:hover .submenu {
    display: none;
  }
  .nav-home-aside .menu-category .has-submenu > a:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .list-inline-item {
    margin-bottom: 10px;
  }
  .slider-home-banner .carousel-item img {
    max-height: 250px;
  }
  .section-header .search-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .section-header .logo {
    max-height: 32px;
    width: auto;
  }
  .banner-quote {
    min-height: auto;
  }
  .icontext {
    margin-bottom: 20px;
  }
  .card-post {
    margin-bottom: 20px;
  }
  .card-product-grid .img-wrap {
    height: 200px;
  }
  .section-footer .title {
    margin-bottom: 0;
    margin-top: 20px;
  }
  .item-feature {
    margin-bottom: 20px;
  }
  .mobile-order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .mobile-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .mobile-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .mobile-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .mobile-block {
    display: block;
  }
  .padding-y {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .padding-top {
    padding-top: 20px;
  }
  .padding-bottom {
    padding-bottom: 20px;
  }
  .padding-y-lg {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .padding-top-lg {
    padding-top: 30px;
  }
  .padding-bottom-lg {
    padding-bottom: 30px;
  }
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .row > [class^="col"] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
